table.ranking-table {
  color: black;
  border-collapse: separate;
  border-spacing: 0 30px;
}

.ranking-table > thead {
  color: white;
}

table.ranking-table > thead > tr > th {
  background-color: inherit;
  color: white;
}

.ranking-table-row > td {
  padding: 20px;
  background-color: #defc5f;
}

.ranking-table-row > td:first-child {
  border-radius: 10px 0 0 10px;
  -moz-border-radius: 10px 0 0 10px;
}

.ranking-table-row > td:last-child {
  border-radius: 0 10px 10px 0;
  -moz-border-radius: 0 10px 10px 0;
}

.minileague-rankings-container {
  margin: 0 calc(30px + 0.75rem);
  position: relative;
}

@media (max-width: 600px) {
  .minileague-rankings-container {
    margin: 20px;
  }
}
