.selector {
  height: 100vh;
}

.left-col {
  background-color: #323232;
}

h1.left-col-text {
  color: #defc5f;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-transform: capitalize;
  white-space: pre;
  margin-bottom: 10px;
}

h4.left-col-follow-text {
  color: #defc5f;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 10px;
}

.left-col-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
}

.left-col-container > * {
  margin: 0 auto;
}

.selector-col > div {
  height: 50%;
  font-family: 'Montserrat';
}

.selector-col > div > div {
  display: flex;
}

.selector-col > div > div > div {
  margin: auto;
}

.option-title {
  text-transform: uppercase;
  font-weight: 700;
}

.option-icon {
  margin: auto;
  filter: brightness(0) invert(1);
}

.option-circle {
  height: 150px;
  width: 150px;
  top: 50%;
  left: 50%;
  transform: translateY(-75px) translateX(-75px);
  background-color: #323232;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
}

.selector-image-container {
  position: relative;
  min-height: 150px;
}

.option-title-link {
  text-decoration: none;
  font-size: 3rem;
  color: black;
}

.option-title-link:hover {
  text-decoration: none;
  color: black;
}
