.left-col-scoring-text {
  color: #323232;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  white-space: pre;
}

.left-col-scoring-container {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.left-col-scoring-outer-container {
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.scored-match-inner-container {
  display: flex;
  margin-bottom: 50px;
  padding: 10px;
}

.scored-match-container > div > div {
  text-align: center;
}

.scored-match-data-container {
  display: flex;
  justify-content: center;
}

.scored-match-data-container > div {
  margin: auto 20px;
  flex: 1;
}

.scored-points-container {
  padding: 10px;
}

.live-score-container,
.predicted-score-container {
  margin: auto;
  padding: 10px;
  border-radius: 5px;
}

.prediction-circle.points-circle {
  height: 50px;
  width: 50px;
  transform: translateX(-50%) translateY(-50%);
}

.scored-header-row {
  margin-bottom: 20px;
  color: white;
  font-family: 'Montserrat';
}

.scored-header-row-inner-container {
  display: flex;
  padding: 10px;
}

.scored-header-row-inner-container > div {
  display: flex;
  justify-content: center;
}

.scored-header-row-inner-container > div > div {
  margin: auto 20px;
  text-align: center;
}

@keyframes pulse-dot {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pulsing-circle {
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  position: absolute;
  display: inline-block;
  margin-left: 10px;
  margin-right: 5px;
  font-size: 18px;
  width: 10px;
  height: 10px;
  border-radius: 45px;
  background-color: red;
  animation: pulse-dot 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

.no-kickoffs-container {
  display: flex;
  height: 100vh;
}

.no-kickoffs-text {
  margin: auto;
  color: white;
  font-family: 'Montserrat';
  font-size: 1.25rem;
}

div.scored-match-data-header > div {
  margin-right: 0;
  margin-left: 0;
  padding: 0;
}

.total-points-container {
  text-align: center;
  font-family: 'Montserrat';
  margin-top: 20px;
  font-size: 1.25rem;
}

@media (max-width: 900px) {
  .scored-match-data-container > div {
    margin: auto 5px;
  }
  .scored-match-data-container {
    min-width: 50vw;
    flex: 1;
    padding: 0;
  }
  .col-lg-8.right-col {
    padding: 0;
  }
  .scored-match-inner-container {
    padding: 5px;
  }
  .scored-header-row-inner-container {
    padding: 5px;
  }
  span.prediction-circle.points-circle {
    width: 30px;
    height: 30px;
  }
  div.scored-points-container {
    margin-left: -10px;
    margin-right: -10px;
  }
  .scored-match-container .club-badge,
  .scored-header-row-inner-container .club-badge {
    height: 40px;
  }
  .scored-match-container .prediction-circle,
  .scored-header-row-inner-container .prediction-circle {
    height: 60px;
    width: 60px;
    transform: translateY(-50%) translateX(-50%);
  }

  .scored-match-data-container.scored-match-data-header > div:first-child,
  .scored-match-data-container.scored-match-data-header > div:nth-child(2) {
    min-width: calc(33% + 10px);
  }
}
