input.form-field,
textarea.form-field {
  border: 0;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
  border-radius: 0;
  font-size: 14px;
  height: 40px;
}

input.form-field:hover,
input.form-field:focus,
textarea.form-field,
textarea.form-field:focus {
  border-color: #ccc;
  outline: none;
  box-shadow: none;
}

.form-buttons {
  width: 50%;
  display: inline-block;
  text-align: center;
  font-size: 14px;
}

button.main-form-button.form-buttons {
  height: 40px;
  background-color: #f6f6f6;
  color: #888888;
  border: 0;
  border-radius: 0;
  font-size: 14px;
}

button.main-form-button.form-buttons:enabled {
  background-color: #6c7868 !important;
  color: white !important;
}

button.main-form-button.form-buttons:enabled:hover {
  color: rgb(202, 202, 202) !important;
}

a.secondary-form-button.form-buttons {
  color: #888;
}

a.secondary-form-button.form-buttons:hover {
  text-decoration: none;
  color: black;
}

div.front-page-outer-container > div > div > div.widget-container,
div.register-form > div > div {
  background-color: white;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px 30px;
  margin: auto;
  max-width: 400px;
}

div.front-page-outer-container > div.front-page-scrollover {
  background-color: #defc5f;
  position: relative;
  height: 100vh;
}
div.front-page-outer-container > div.front-page-scrollover > div {
  min-height: 100vh;
}
div.front-page-outer-container
  > div.front-page-scrollover
  > div
  > div.widget-container {
  display: flex;
}

.front-page-top {
  height: 100vh;
  background-image: url('icons/saltbeef.png');
  background-size: cover;
  position: relative;
  width: 100%;
  display: flex;
  background-attachment: fixed;
}

.front-page-top > .text-container {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 100px;
}

.front-page-placeholder {
  height: 100vh;
}

.site-header {
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 5rem;
}

.site-tagline {
  color: #defc5f;
  font-weight: 400;
}

.saltbeef-background-col {
  background-image: url('icons/square_saltbeef.jpg');
  background-size: cover;
}

div.login-button-outer-container {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 99;
}

.login-button-outer-container > button {
  border: none;
  font-family: 'Montserrat';
}

.login-popup {
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

div.login-popup-content {
  border-radius: 15px;
  background-color: #fefefe;
  margin: auto; /* 15% from the top and centered */
  padding: 20px 30px;
  border: 1px solid #888;
  width: 450px; /* Could be more or less, depending on screen size */
  position: relative;
  font-family: 'Montserrat';
}

.login-popup-close {
  background-position: center;
  height: 50px;
  width: 50px;
  background-size: 50px !important;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent !important;
}

.login-popup-close:hover {
  filter: invert(0.5);
  cursor: pointer;
}

@media (max-width: 700px) {
  div.front-page-top > div.text-container {
    margin: auto;
  }
}

@media (max-width: 425px) {
  div.front-page-outer-container > div > div > div.widget-container,
  div.register-form > div > div {
    max-width: 300px;
  }
}
