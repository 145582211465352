.minileague-prediction-table {
  color: black;
  border-collapse: collapse;
  border-spacing: 0 30px;
  table-layout: fixed;
  --bs-table-bg: initial;
  --bs-table-color: initial;
}

.minileague-prediction-table > thead {
  color: white;
}

.minileague-prediction-table th {
  text-align: center;
}

.minileague-prediction-table-row-inner-container {
  padding: 20px;
  background-color: #defc5f;
  border-radius: 10px;
}

.minileague-prediction-table-row > td {
  margin: 10px 0;
}

.minileague-prediction-container {
  margin: 0 10px;
}

tr.minileague-prediction-table-row-temp {
  border-top: 1px solid black;
}

tr.minileague-prediction-table-row-temp > td:first-child {
  margin-top: 0;
  border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
}

tr.hide-curved-corners > td:first-child {
  border-radius: 10px 0 0 0;
}

tr.hide-curved-corners > td:last-child {
  border-radius: 0 10px 0 0;
}

.minileague-scoring-circle {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

div.minileague-prediction-table-row-inner-container > div:last-child {
  margin-bottom: 0;
}

.minileague-prediction-row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  table.minileague-prediction-table thead {
    display: none;
  }

  table.minileague-prediction-table td {
    display: block;
  }
}
