.minileague-pills {
  margin: 30px;
  margin-bottom: 0;
}

.nav-pills .nav-link.active {
  background-color: #defc5f;
  color: black;
}

.nav-pills .nav-link {
  font-family: 'Montserrat';
  color: white;
}

.nav-pills .nav-link:not(.active):hover {
  background-color: #526402;
}

.nav-item {
  margin: 0 0.75rem;
}

.mini-table {
  font-family: 'Montserrat';
}

h4.left-col-minileague-text {
  margin-top: 20px;
  text-align: center;
  color: black;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 10px;
}

.no-mini-league-statement-container {
  display: flex;
  height: 100vh;
}

.no-mini-league-statement {
  margin: auto;
  font-family: 'Montserrat';
  color: white;
  font-size: 1.25rem;
}

.nav-fill .nav-item {
  flex: 1;
}

div.ask-friends-minileague {
  top: 50%;
  position: absolute;
  text-align: center;
  left: 0;
  margin-right: 20px;
  margin-left: 20px;
  width: calc(100% - 40px);
}
