.kick-off-time-container {
  text-align: center;
}

.outer-pred-container {
  max-width: 800px;
  margin: auto;
  background-color: #2c2c2c;
  border-radius: 25px;
  color: white;
}

.pred-container {
  display: flex;
  padding: 20px;
}

.pred-container > div {
  display: inline;
  min-width: 33.333%;
  text-align: center;
}

.live-score {
  margin: auto;
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
}

@keyframes pulse-dot {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pulsing-circle {
  margin-right: 5px;
  font-size: 18px;
  width: 10px;
  height: 10px;
  border-radius: 45px;
  background-color: white;
  animation: pulse-dot 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.right-col {
  z-index: 99999;
  background-color: #323232;
  min-height: 100vh;
}

.left-col-prediction-text {
  color: #323232;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  white-space: pre;
}

.left-col-prediction-container {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  max-width: 308px;
}

.left-col-prediction-outer-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.predictions-form {
  margin-top: 50px;
}

.predictions-form-submit-button {
  margin-top: 10px;
  border: none;
  background-color: #323232;
  color: white;
  border-radius: 5px;
  font-family: 'Montserrat';
  padding: 5px 0;
}

.predictions-form-submit-button:active {
  background-color: black;
}

.left-col-prediction-outer-container > div.alert {
  position: fixed;
  margin-top: 10px;
}

.score-container {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #313131;
  padding: 20px;
  border-radius: 10px;
  color: white;
}

.prediction-circle {
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  transform: translateY(-50px) translateX(-50px);
  background-color: #defc5f;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  z-index: -1;
}

.home-team-container,
.away-team-container {
  position: relative;
}

.team-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  color: white;
}

.club-badge {
  z-index: 999;
  margin-right: auto;
  margin-left: auto;
}

.club-name {
  margin-top: 10px;
}

.prediction-score-input {
  width: 30px;
  height: 30px;
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid black;
}

.predictions-form-submit-button-mobile {
  display: none;
}

.chips-container {
  margin-top: 10px;
  margin-bottom: -5px;
  display: flex;
  justify-content: center;
}

.chip-icon-button {
  border: none;
  background: none;
}

.chip-icon {
  filter: invert();
}

.explanation-row-container {
  margin: 5px;
}

@media (max-width: 600px) {
  .club-badge {
    height: 50px;
  }

  .prediction-circle {
    height: 70px;
    width: 70px;
    transform: translateY(-50%) translateX(-50%);
  }

  .pred-container {
    padding: 5px;
  }

  div.pred-container > div.home-team-container,
  div.pred-container > div.away-team-container {
    min-width: 20%;
  }

  .pred-container > div {
    width: 100%;
  }

  .prediction-score-input {
    height: 40px;
    width: 35px;
    box-shadow: none;
  }
}

@media (max-width: 991px) {
  input.predictions-form-submit-button-mobile {
    display: block;
    margin: auto;
    background-color: #defc5f;
    color: black;
    padding: 10px 20px;
    margin-bottom: 20px;
  }

  .predictions-form-submit-button {
    display: none;
  }

  form.predictions-form
    .outer-container:nth-last-child(2)
    > .outer-pred-container {
    margin-bottom: 20px;
  }
}
