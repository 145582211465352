select {
  appearance: none;
  border: 1px #323232 solid;
  background-color: #323232;
  padding: 5px 10px;
  color: white;
  font-family: 'Montserrat';
  background-image: url('icons/arrow.png');
  width: 100%;
  padding-right: 25px;
  width: fit-content;
  min-width: 100%;
}

select:disabled {
  filter: contrast(0.4);
}

.dropdown-arrow {
  position: absolute;
  filter: invert();
  margin-top: 0.5rem;
  right: 7px;
  pointer-events: none;
}

.dropdown-container {
  position: relative;
}
